@import './App.scss';

.current-screen-style {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
html {
  // background-color: $htmlColor;
}
.App {
  text-align: center;
  height: 100%;
  // background-color: $AppColor;
}

.background-image {
  background-image: url('./site_background.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
.background-image::before {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255,255,255,.85);
}
#root {
  height: 100%;;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Main-headerTxt {
  position: absolute;
  width: 100%;
  background-color: blue;
  color: white;
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.main-layout-grid {
  display: grid;
  grid-template-rows:  50px 5px 5px 5px 5px 20px 48px auto  42px; 
  grid-template-columns: 20px auto 20px;
  grid-template-areas: 
    "header header header"
    "spacer1 spacer1 spacer1"
    "spacer2 spacer2 spacer2"
    "spacer3 spacer3 spacer3"
    "spacer4 spacer4 spacer4"
    "spacer5 spacer5 spacer5"
    "left-space main-navigation right-space"
    "left-side main right-side"
    "footer footer footer";
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}



.main-content {
  grid-area: main;
  scroll-behavior: smooth;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.main-content::-webkit-scrollbar {
  display: none;
}

.header {
  grid-area: header;
  background-color: $headerColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spacer1 {
  grid-area: spacer1;
  background-color: $spacer1Color;
}
.spacer2 {
  grid-area: spacer2;
  background-color: $spacer2Color;
}
.spacer3 {
  grid-area: spacer3;
  background-color: $spacer3Color;
}
.spacer4 {
  grid-area: spacer4;
  background-color: $spacer4Color;
  height: 5px;
}
.header-left-image {
  background-image: url(/images/Napi.png);
  width: 100px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.header-center-image {
  background-image: url(/images/piikanipaitapiisin.png);
  width: 270px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.header-right-image {
  background-image: url(/images/NapiWife.png);
  width: 50px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 420px) {
  .main-layout-grid {
    grid-template-columns: 4px auto 4px;
    grid-template-areas:
      "header"
      "spacer1"
      "spacer2"
      "spacer3"
      "spacer4"
      "spacer5"
      "left-space main-navigation right-space"
      "left-side main right-side"
      "footer";
  }

  .header-left-image {
    width: 62px;
    // height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .header-center-image {
    width: 230px;
    // height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .header-right-image {
    width: 62px;
    // height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.footer {
  // background-image: $footerImage;
  grid-area: footer;
  position: relative;
  @extend .clientConfig-footerStyle;
}

.main-nav {
  grid-area: main-navigation;
}
.main-nav-left {
  justify-self: stretch !important;
  align-self: stretch !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ui.button.active {
  font-size: larger;
}
