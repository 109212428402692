.new-game-form-container {
  margin: 20px 50px 0;
  width: clamp(600px, 100%, 750px);
}
.new-game-form {
  width: 100%;
  background-color: rgba(200,200,200,1);
  border-radius: 10px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
}
.new-game-form label {
  margin-bottom: 0 !important;
}
.new-game-row-1-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.new-game-row-group {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.new-game-row-group-first {
  @extend .new-game-row-group;
  margin-bottom: 0px;
}
.new-game-row-1-lower {
  color: rgba(240,240,240,1);
  font-weight: bold;
  margin-top: 3px;
}
.new-game-row-2-container {
  @extend .new-game-row-group;
}
.new-game-row-2-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.new-game-row-2-group:first-child {
  margin-right: 5px;
}
.new-game-modal-button {
  margin: 3px !important;
}