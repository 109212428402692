.nav-admin-semantic-menu {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  z-index: 999;
  background-color: rgba(28,28,28,1);
  padding: 3px;
  padding-right: 0;
  border-radius: 4px;
  // height: 42px;
}
.nav-admin-close-button {
  // height: 30px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: default;
  :hover {
    background-color: rgba(28,28,28,0.20);
  }
}

.nav-admin-small-closed-menu {
  background-color: rgba(28,28,28,1);
  width: 40px;
  height: 40px;
  display: grid;
  border-radius: 4px;
  cursor: pointer;
}
.nav-admin-small-closed-menu-icon {
  align-self: center;
}

.nav-admin-full-menu {
  background-color: rgba(34,34,34,1);
  border-radius: 3px;
  display: flex;
  margin: 5px;
  width: 100%;
}

.nav-admin-item {
  width: 100%;
  padding: 12px 5px;
}
.nav-admin-item:not(:last-child) {
  border-color: rgba(100,100,100,1);
  border-style: solid;
  border-width: 0 1px 0 0;
}
.nav-admin-vertical-item {
  width: 100%;
  height: 40px;
  padding: 1px;
}
.nav-admin-active {
  background-color: rgba(80,80,80,1);
  border-radius: 4px;
}
.nav-admin-item:hover, .nav-admin-vertical-item:hover .nav-admin-text {
  font-weight: bold;
}
.nav-admin-text {
  color: white;
  white-space: nowrap;
  // background-color: antiquewhite;
  
}

.nav-log-button-active {
  border: 1px gold solid;
}

.nav-log-button {
  // background-color: blue;
}
.nav-admin-menu-last {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}