.centerme {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.englishTxtHeader {
  font-weight: 400;
  font-style: italic;
  padding-left: 1em;
}
i.icon {
  font-size: inherit;
} 
.audio-text {
  margin-top: 5px;
} 
.fixiconbutton {
  padding-bottom: 1em;
}
// .modalKat {
//   background-color: red !important;
// }
.modalKat .header {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: rgb(65,65,65) !important;
  color: white !important;
  border-width: 0px !important;
}
.modalKat .footer {
  background-color: rgb(65,65,65) !important;
  border-width: 0px !important;
}
.MediaLibrary-GridItems-Container {
  display: flex;
  // flex-direction: column;
  margin: 0 60px;
  // border: 1px black solid;
  padding: 10px;
  align-items: stretch;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.MediaLibrary-GridItems {
  background-color: rgb(226, 151, 85);
  border-radius: 10px;
  border: 1px black solid;
  padding: 5px;
  margin: 5px 1%;
  // flex: 1;
  // min-width: 200px;
  width: #{"clamp(140px, 18%, 190px)"};
  // width: #{"max(18%, 200px)"};
}
.MediaLibrary-GridItems-Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  background-color: rgb(19, 108, 176);
  min-height: 2em;
  border-radius: 7px 7px 0 0;
  color: whitesmoke;
  padding: 0 5px 0;
  flex-wrap: nowrap;
}
.MediaLibrary-Header-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.5);
  padding: 0.2em 0.3em 0.4em 0.3em;
}
.MediaLibrary-GridItems-Header-Content {
  // flex: 1 0 auto;
  flex: 1;
  font-weight: 600;
}
.MediaLibrary-GridItems-content {
  margin: 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;

}
.ML-filterBox {
  display: flex;
  flex-wrap: wrap;
  padding-left: 3em;
}
.ML-filterBox-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5em 1em;
}
.ML-imageBox img {
  max-width: 98%;
}
.ML-expandedImageView {
  border-width: 0 2px 0 0;
  border-color: rgb(100,100,100);
  border-style: solid;
  padding: 20px 30px;
}
.GridLayout-modal-grid {
  display: flex;
  justify-content: space-between;
}

.mediaLibrary-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  margin: 20px 60px 0;
  grid-auto-rows: 65px;
}
.grid-item {
  background: linear-gradient(150deg, rgba(234,220,200,1), rgba(200,180,165,1));
  // background: linear-gradient(150deg, rgba(240,224,137,1), rgba(141,119,39,1));
  border-radius: 7px;
  box-shadow: black 1px 1px 4px;
}

.ML-upperRight {
  position: absolute;
  right: 5px;
  top: 10px;
  min-height: 5px;
  min-width: 20px;
  display: flex;
}
