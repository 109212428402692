.inputControlled-container {
    position: relative;
}

.inputControlled-dd {
    position: absolute !important;
    left: -0.5em;
    top: 0.6em;
    z-index: 9999;
}

.inputControlled {
    display: flex;
    position: relative;
    width: 100%;
}

.inputControlled-specialChars {
    position: absolute;
    z-index: 999;
    left: 5em;
    top: -1.2em;
    width: max-content;
}