.showMediaUploadModal {
  display: flex;
  position: fixed;
  overflow: auto;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200,200,200,0);
}

.showMediaUploadModalBG {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

}

.simpleMediaPreview-mainMedia {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.simpleMediaPreview-mainMedia-img {
  border: 1px solid #000;
  box-shadow: 10px 10px 20px;
}
.simpleMediaPreview-img {
  border: 1px solid #000;
}
.simpleMediaPreview-imagemainContainer {
  position: relative;
}

.simpleMediaPreview-img-btn {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 5px;
}
