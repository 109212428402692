.admin-login-fullScreen {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.admin-login-container {
    margin: 10px;
    padding: 10px;
    background:
        linear-gradient(
            135deg,
            rgba(19,108,177,1),
            rgba(19,70,130,1),
        );
    border-radius: 10px;
    max-width: 350px;
    // height: 500px;
    display: flex;
    flex-direction: column;
}
.admin-login-container h1 {
    color: white;
    margin-top: 15px;
    margin-bottom: 30px;
}
.admin-login-buttons {
    align-self: stretch;
    margin: 0 7px 10px 7px !important;
}
.admin-login-buttons-container {
    @extend .admin-login-buttons;
    display: flex;
    justify-content: center;
}
.admin-login-bottom-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 10px;
}
.admin-login-button-alternate {
    flex: 1;
    margin: 0 7px !important;
}
.admin-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: stretch;
}
.admin-login-or-bar {
    color: white;
    display: flex;
    font-weight: bold;
    align-items: center;
    margin: 10px 15px 20px;
    width: 100%;
}
.admin-login-or-bar hr {
    border-width: 1px;
}
.admin-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.manageUsers-toolbar {
    padding: 1em 0em;
}

.datagrid-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-access-lvl-cell {
    padding: .6em 1em .6em 1em ;
}

.admin-userDetailDisplay {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & > .input {
        margin-top: 1em;
    }
    ul {
        list-style-type: none;
        li {
            display: flex;
            justify-content: flex-start;
        }
    }
}

.labelFix {
    font-size: 1em !important;
    width: fit-content;
}

.admin-user-detail-secondary-text {
    font-style: normal;
    font-family: monospace;
    color: rgba(0,0,0,0.4);
}