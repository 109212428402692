.addColumnModal-fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999999999999;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
$addColumnModal-container-height: clamp(300px, 60vh, 600px);
$addColumnModal-container-width: clamp(550px, 85vw, 1000px);
.addColumnModal-container {
  height: $addColumnModal-container-height;
  width: $addColumnModal-container-width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.modal-components {
  border: rgba(27,28,29,1) 3px solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}
.addColumnModal-header {
  @extend .modal-components;
  border-bottom-width: 0;
  border-radius: 10px 10px 0 0;
  width: 100%;
  background-color: rgba(35,133,208,1);
}
.addColumnModal-header-text {
  font-weight: bold;
  font-size: 2em;
  padding: 15px;
  color: white;
}
.addColumnModal-content {
  @extend .modal-components;
  padding-top: 15px;
  border-top-width: 0;
  border-bottom-width: 0;
  background-color: rgba(235,235,235,1);
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
}
.addColumnModal-content::-webkit-scrollbar {
  display: none;
}
.addColumnModal-content-advanced-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 2px;
}
hr {
  // flex: 1;
  width: 180px;
  margin: 10px;
  // border-width: 2px;
}
.addColumnModal-content-advanced-bar-arrow {
  width: 10px;
  height: 15px;
  font-size: 15px;
  margin-left: 5px;
}
.addColumnModal-actions {
  @extend .modal-components;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-top-width: 0;
  border-radius: 0 0 10px 10px;
  width: 100%;
  background-color: rgba(215,215,215,1);
  position: relative;
  z-index: 10000;
  padding: 15px;
  transition: 1000ms;
}
.addColumnModal-actions-finish-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
$warningFontSize: 15px;
.addColumnModal-actions-warning-text {
  color: red;
  font-size: $warningFontSize;
  vertical-align: text-bottom;
}
.addColumnModal-actions-warning-text-hidden {
  display: flex;
  align-items: flex-end;
  height: 0px;
  overflow: hidden;
  transition: 300ms;
}

.addColumnModal-actions-warning-text-visible {
  @extend .addColumnModal-actions-warning-text-hidden;
  height: 1.5em;
}

.addColumnModal-actions-left {
  display: flex;
  flex-direction: row;
}
.addColumnModal-actions-right {
  display: flex;
  flex-direction: row;

}
.mediauploadmodal-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.mediauploadmodal-content-rightcontent {
  display: flex;
  flex: 1;
  
}
.mediauploadmodal-content-rightcontent-imagecontainer {
  display: flex;
  flex-direction: column;
  margin: 2px;
  padding: 5px;
  width: 50%;
  height: 100%;
}
.mediauploadmodal-content-rightcontent-imagecontainer p {
  font-size: 1.2em;
  line-height: 1em;
  font-weight: bold;
  background-color: yellowgreen;
  padding: 5px;
  border-radius: 3px;
}
.mediauploadmodal-content-rightcontent-image {
  width: 100%;
  object-fit: contain;
  // resize: 100% 100%;
}